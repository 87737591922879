<template>
  <v-container>
    <div>
      <v-card rounded="lg" class="pa-5">
        <v-card-subtitle class="d-flex align-center">
          <div class="page-title mr-5">{{ $route.meta.title }}</div>
          <v-btn color="primary" class="px-6" depressed small :disabled="isFetching" @click="addFAQ">新增</v-btn>
        </v-card-subtitle>
        <v-card-text class="my-5">
          <DraggableTable
            :tableHeader="faqHeader"
            :data="faq"
            @setOrder="setFAQOrder"
            @dragTableAction="faqAction"
          ></DraggableTable>
        </v-card-text>
      </v-card>

      <CustomDialog
        :isDeleteDialog="false"
        :dialog="openFAQDialog"
        :text="faqDialogIndex === -1 ? '新增常見問題' : '更改常見問題'"
        @close="updateFAQ"
        :eager="true"
      >
        <template v-slot>
          <v-form ref="faq-form">
            <v-row class="mt-3">
              <v-col cols="12">
                <form-control
                  inputType="string"
                  v-model="faqData.question"
                  label="問題"
                  :required="true"
                  :disabled="isFetching"
                />
              </v-col>
              <v-col cols="12">
                <form-control
                  inputType="textarea"
                  v-model="faqData.answer"
                  label="答案"
                  :required="true"
                  :disabled="isFetching"
                />
              </v-col>
            </v-row>
          </v-form>
        </template>
      </CustomDialog>

      <v-row class="pt-7">
        <v-col cols="12">
          <v-btn color="primary white--text" class="px-6" small depressed :disabled="isFetching" @click="saveFAQ">
            儲存
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import DraggableTable from '@/components/DraggableTable'
import CustomDialog from '@/components/CustomDialog.vue'

export default {
  name: 'FAQ',
  components: {
    FormControl,
    DraggableTable,
    CustomDialog,
  },
  data() {
    return {
      faqHeader: [
        { text: '問題', value: 'question', minWidth: '125px', width: '15%' },
        { text: '答案', value: 'answer', minWidth: '300px', width: '70%' },
        { text: '', value: 'editBtn', width: '15%' },
        { text: '', value: 'deleteBtn', width: '15%' },
      ],
      faq: [],
      openFAQDialog: false,
      faqDialogIndex: -1,
      faqData: {
        question: '',
        answer: '',
      },
      id: -1,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getFAQ() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('get_system', payload)
        this.$func.log('-----Get FAQ-----')
        this.$func.log(result)

        this.faq = result.faq
        this.id = result.id
      } catch (error) {
        this.$func.log('-----Get FAQ Fail-----')
        this.$func.log(error)
        this.categoryData = []
      } finally {
        this.setFetching(false)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    addFAQ(event, isAdd = true, index = -1) {
      if (isAdd) {
        this.faqData = {
          question: '',
          answer: '',
        }
        this.faqDialogIndex = -1

        if (this.$validate.DataValid(this.$refs['faq-form'])) {
          this.$refs['faq-form'].reset()
        }
      } else {
        console.log(this.faq[index])
        this.faqDialogIndex = index
        this.$set(this.faqData, 'question', this.faq[index].question)
        this.$set(this.faqData, 'answer', this.faq[index].answer)
      }
      this.openFAQDialog = true
    },

    updateFAQ(action) {
      if (action) {
        const validate = this.$refs['faq-form'].validate()
        if (validate) {
          if (this.faqDialogIndex !== -1) {
            this.$set(this.faq[this.faqDialogIndex], 'question', this.faqData.question)
            this.$set(this.faq[this.faqDialogIndex], 'answer', this.faqData.answer)
          } else {
            this.faq.push({
              question: this.faqData.question,
              answer: this.faqData.answer,
            })
          }

          this.faqData = {
            question: '',
            answer: '',
          }

          this.openFAQDialog = false
        }
      } else {
        this.openFAQDialog = false
      }
    },

    setFAQOrder(data) {
      this.faq = data
    },

    faqAction(type, index) {
      if (type === 'edit') {
        this.addFAQ(null, false, index)
      } else if (type === 'delete') {
        this.faq.splice(index, 1)
      }
    },

    async saveFAQ() {
      if (!this.isFetching) {
        this.setFetching(true)

        try {
          const payload = {
            id: parseInt(this.id),
            verify_token: this.getAdminToken(),
            faq: this.faq,
          }

          const result = await this.$XHR.api('cms_update_faq', payload)
          this.$func.log('-----Update FAQ-----')
          this.$func.log(result)

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: null,
            refresh: true,
          })

          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('-----Update FAQ Fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'system data does not exist') {
            message = '資料不存在'
          }

          this.setFetching(false)
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.setFetching(true)
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getFAQ()
    }
  },
}
</script>
